import { useEffect, useState } from "react";
import api from "../services/api";
import Pagination from "../components/Shared/Pagination";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { OrderDetailModal } from "../components/Orders/OrderDetailModal";
import {
  Card,
  CardBody,
  Flex,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ResponsiveTable } from "../components/Orders/ResponsiveTable";
import { Page } from "../components/Shared/Page";
import { IconFileSpreadsheet } from "@tabler/icons-react";

export default () => {
  useDocumentTitle("Orders");

  const toast = useToast();

  const keys = [
    [
      { name: "Order ID", id: "_id" },
      { name: "Job Name", id: "name" },
      { name: "Company Name", id: "companyName" },
      { name: "Ship Date", id: "shipDate" },
      { name: "Requested Ship Date", id: "requestedShipDate" },
    ],
    [
      { name: "Entry ID", id: "_id" },
      { name: "Quantity", id: "quantity" },
      { name: "Type", id: "type" },
      { name: "Limits Group", id: "limitsGroup" },
      { name: "Start Date", id: "starTdate" },
      { name: "Has Started", id: "hasStarted" },
    ],
  ];

  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({ level: 0, key: "_id", value: "" });
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadWipReport = async () => {
    try {
      const response = await api(
        "/api/orders/export/start-date",
        "GET",
        "",
        "",
        true
      );

      const url = window.URL.createObjectURL(response);

      const link = document.createElement("a");
      link.href = url;
      link.download = "WIP Report.csv";

      link.click();

      window.URL.revokeObjectURL(url);

      link.remove();
    } catch (error) {
      toast({
        title: "An error occured",
        status: "error",
        duration: 2000,
        isClosable: true,
        description: error.data?.data?.message || error.message,
      });
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsLoading(true);

        let endpoint = `/api/orders`;
        let meThod = "get";
        let options = { body: "", query: { page, limit } };

        if (
          filter.level !== null &&
          filter.key !== null &&
          filter.value !== ""
        ) {
          endpoint = `/api/orders/filter`;
          meThod = "post";
          options.body = {
            key: filter.key,
            value: filter.value,
            filterByDateEntries: filter.level == 1 ? true : false,
          };
        }

        const { data } = await api(endpoint, meThod, options);

        setOrders(data.orders);
        setTotalPages(data.pagination.totalPages);
        setTotalOrders(data.pagination.total);
      } catch (error) {
        toast({
          title: "An error occured",
          status: "error",
          duration: 2000,
          isClosable: true,
          description: error.data?.data?.message || error.message,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [filter, limit, page]);

  return (
    <Page
      title="Orders"
      buttons={[
        {
          text: "WIP Report",
          icon: IconFileSpreadsheet,
          colorScheme: "blue",
          onClick: downloadWipReport,
        },
      ]}
      cardBodyPadding={0}
    >
      <OrderDetailModal order={order} isOpen={isOpen} onClose={onClose} />
      <CardBody>
        <Flex flexDirection={{ base: "column", md: "row" }}>
          <HStack
            marginBottom={{ base: 4, md: 0 }}
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="sm">Show</Text>
            <NumberInput
              size="sm"
              width="80px"
              value={limit}
              onChange={setLimit}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text fontSize="sm">entries</Text>
          </HStack>
          <Spacer />
          <HStack
            alignItems="center"
            justifyContent="center"
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <Text fontSize="sm">Search</Text>
            <Select
              size="sm"
              value={filter.level}
              onChange={(e) => setFilter({ ...filter, level: e.target.value })}
            >
              <option key={0} value={0}>
                Order Details
              </option>
              <option key={1} value={1}>
                Date Entries
              </option>
            </Select>
            <Select
              size="sm"
              value={filter.key}
              onChange={(e) => setFilter({ ...filter, key: e.target.value })}
            >
              {keys[filter.level].map((key) => (
                <option key={key.id} value={key.id}>
                  {key.name}
                </option>
              ))}
            </Select>
            <Input
              type="text"
              size="sm"
              value={filter.value}
              onChange={(e) => setFilter({ ...filter, value: e.target.value })}
            ></Input>
          </HStack>
        </Flex>
      </CardBody>
      <ResponsiveTable orders={orders} onOpen={onOpen} setOrder={setOrder} />
      <CardBody>
        <Flex flexDirection={{ base: "column", md: "row" }}>
          <HStack
            marginBottom={{ base: 4, md: 0 }}
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              Showing {page * limit - limit} to {page * limit} of {totalOrders}{" "}
              entries
            </Text>
          </HStack>
          <Spacer />
          <HStack
            alignItems="center"
            justifyContent="center"
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChange={setPage}
            />
          </HStack>
        </Flex>
      </CardBody>
    </Page>
  );
};
