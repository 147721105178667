import { useState, useEffect, useCallback } from "react";
import { DateTime, Interval } from "luxon";
import api from "../services/api";

const changeStartDate = (
  row,
  shipSelection,
  startDate,
  applyToOrder,
  refresh,
  setRefresh,
  onClose,
  setIsLoading,
  order
) => {
  const [shipDate, setShipDate] = useState("");
  const [alert, setAlert] = useState(null);
  const bc = new BroadcastChannel("daily_totals");

  const days = useCallback(function* (interval) {
    let cursor = interval.start.startOf("day");
    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }, []);

  const calculateRPD = useCallback(
    (requiredProductionDays, requestedStartDate) => {
      const initialShipDate = DateTime.fromISO(requestedStartDate).plus({
        days: requiredProductionDays,
      });
      const numWeekDays = Array.from(
        days(Interval.fromDateTimes(requestedStartDate, initialShipDate))
      ).reduce((acc, d) => acc + (d.weekday === 6 || d.weekday === 7), 0);

      return requiredProductionDays + numWeekDays;
    },
    [days]
  );

  const saveChanges = useCallback(async () => {
    setIsLoading(true);
    const luxonShipDate = shipDate ? DateTime.fromISO(shipDate) : null;
    let endpoint = "/api/orders/update/start-date";
    let body = { ...row, updatedStartDate: startDate, applyToOrder };

    if (shipSelection !== "dontAdjust") {
      body.shipDate = luxonShipDate;
    } else {
      body.shipDate = null;
    }

    if (applyToOrder) {
      endpoint = "/api/orders/update/multiple-start-date";
      body = { ...order, updatedStartDate: startDate };
    }

    try {
      const response = await api(endpoint, "put", { body });

      if (response.status === "success") {
        setRefresh(refresh + 1);
        bc.postMessage(true);
        onClose();
      }
    } catch (error) {
      setAlert({
        variant: "error",
        message: error?.data?.data?.message || "An error occurred",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    shipDate,
    setIsLoading,
    row,
    startDate,
    applyToOrder,
    shipSelection,
    setRefresh,
    bc,
    onClose,
    setAlert,
    order,
  ]);

  useEffect(() => {
    if (shipSelection === "autoAdjust" && startDate) {
      const luxonStartDate = DateTime.fromISO(startDate);
      const rpd = parseInt(row.rpd, 10);
      const updatedRPD = calculateRPD(rpd, luxonStartDate);

      let adjustedShipDate = luxonStartDate.plus({ days: updatedRPD });
      if ([6, 7].includes(adjustedShipDate.weekday)) {
        adjustedShipDate = adjustedShipDate.plus({
          days: adjustedShipDate.weekday === 6 ? 2 : 1,
        });
      }

      setShipDate(adjustedShipDate.toISODate());
    }
  }, [startDate, shipSelection, calculateRPD]);

  return { shipDate, setShipDate, saveChanges, alert };
};

export default changeStartDate;
