import { useState, useEffect } from "react";
import api from "../../services/api";
import { useToast } from "@chakra-ui/react";
const { DateTime } = require("luxon");

const useFetchRows = (setIsLoading) => {
  const toast = useToast();
  const [rows, setRows] = useState([]);
  const [activeRow, setActiveRow] = useState({ id: null });
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchRows = async () => {
      try {
        setIsLoading(true);

        const {
          data: { orders }
        } = await api("/api/orders/filter", "post", {
          body: { filterByDateEntries: true, key: "hasStarted", value: false },
          query: { includeAll: true }
        });

        const { data: savedAttributes } = await api(
          "/api/allmoxy/products/attributes/saved/",
          "get"
        );

        const { data: limits } = await api("/api/settings/limits", "get");

        const { data: orderFlags } = await api(
          "/api/settings/order-flags",
          "get"
        );

        const rows = orders.reduce((acc, order) => {
          const orderRows = order.dateEntries.map(
            ({
              _id,
              quantity,
              type,
              startDate,
              highlight,
              limitsGroup,
              allmoxyData
            }) => {
              const { rpd } = limits.find((x) => x.name === limitsGroup);

              const transformedAttributes = allmoxyData?.productAttributes
                .map((attribute) => {
                  const savedAttribute =
                    savedAttributes.find(
                      (attr) =>
                        attr.attributeId.toString() ===
                        attribute.attributeId.toString()
                    ) || null;

                  if (!savedAttribute) return;

                  const { displayName, displayOn } = savedAttribute;

                  return {
                    ...attribute,
                    displayName,
                    displayOn
                  };
                })
                .filter((attr) => attr !== undefined);

              return {
                id: _id,
                orderID: order._id,
                name: order.name,
                companyName: order.companyName,
                quantity,
                type,
                startDate,
                shipDate: DateTime.fromISO(order.shipDate).toISODate(),
                highlight,
                rpd,
                revisedShipDate: order.revisedShipDate,
                highlightColor:
                  orderFlags.find(
                    (flag) =>
                      flag.products?.includes(type) ||
                      flag.tags?.some(
                        (tag) =>
                          order.orderTags?.includes(tag) ||
                          order.companyTags?.includes(tag)
                      )
                  )?.highlightColor ?? null,
                allmoxyData:
                  transformedAttributes?.length > 0
                    ? transformedAttributes
                    : null
              };
            }
          );
          return [...acc, ...orderRows];
        }, []);

        rows.sort((a, b) => parseInt(a.orderID) - parseInt(b.orderID));

        setRows(rows);
      } catch (error) {
        toast({
          title: "An error occured",
          description: error.data?.data?.message || error.message,
          status: "error",
          isClosable: true,
          duration: 5000
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchRows();
  }, [refresh]);

  return { rows, activeRow, setActiveRow, refresh, setRefresh };
};

export default useFetchRows;
