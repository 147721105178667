import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useBreakpointValue,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { DateTime } from "luxon";

const OrderDetailModal = ({ order, isOpen, onClose }) => {
  const direction = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Order Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack mb={4} direction={direction}>
            <FormControl>
              <FormLabel>Order ID</FormLabel>
              <Input type="text" readOnly value={order?._id} />
            </FormControl>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input type="text" readOnly value={order?.companyName} />
            </FormControl>
            <FormControl>
              <FormLabel>Job Name</FormLabel>
              <Input type="text" readOnly value={order?.name} />
            </FormControl>
          </Stack>
          <Stack mb={4} direction={direction}>
            <FormControl>
              <FormLabel>Ship Date</FormLabel>
              <Input
                type="text"
                readOnly
                value={DateTime.fromISO(order?.shipDate).toISODate()}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Total Pieces</FormLabel>
              <Input
                type="text"
                readOnly
                value={order?.dateEntries?.reduce(
                  (total, e) => total + e.quantity,
                  0
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Pieces Started</FormLabel>
              <Input
                type="text"
                readOnly
                value={order?.dateEntries?.reduce(
                  (total, e) => (e.hasStarted ? total + e.quantity : total),
                  0
                )}
              />
            </FormControl>
          </Stack>
          <Stack mb={4} direction={direction}>
            <FormControl>
              <FormLabel>Order Tags</FormLabel>
              <Input type="text" readOnly value={order?.orderTags} />
            </FormControl>
            <FormControl>
              <FormLabel>Company Tags</FormLabel>
              <Input type="text" readOnly value={order?.companyTags} />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalBody>
          <Box mb={4} borderWidth="1px" borderRadius="md" overflow="hidden">
            <Table variant="striped" colorScheme="gray" size="sm">
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Quantity</Th>
                  <Th>Start Date</Th>
                  <Th>Category</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {order?.dateEntries &&
                  order.dateEntries.map((dateEntry, index) => (
                    <Tr key={index}>
                      <Td>{dateEntry.type}</Td>
                      <Td>{dateEntry.quantity}</Td>
                      <Td>{dateEntry.startDate}</Td>
                      <Td>{dateEntry.limitsGroup}</Td>
                      <Td>
                        {dateEntry.hasStarted ? "Started" : "Not Started"}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { OrderDetailModal };
