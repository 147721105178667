import { useState, useEffect } from "react";
import io from "socket.io-client";
import api from "../../services/api";
import { Page } from "../../components/Shared/Page";
import { IconReload } from "@tabler/icons-react";
import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps
} from "@chakra-ui/react";

const RegenerateTotals = () => {
  const [socketConnection, setSocketConnection] = useState(false);
  const [order, setOrder] = useState(0);
  const [lastOrder, setLastOrder] = useState("unknown");
  const [percentage, setPercentage] = useState(0);
  const [socketID, setSocketID] = useState(null);

  const steps = [
    {
      id: "socketConnection",
      title: "Connection Status",
      description: socketConnection
        ? "A connection has been established"
        : "No connection is currently established with the backend server. Unable to continue"
    },
    {
      id: "dropDailyTotals",
      title: "Delete Existing Totals",
      description:
        "Removing all existing daily totals objects from the database"
    },
    {
      id: "fetchOrders",
      title: "Fetching Orders",
      description: "Fetching all orders currently in the system"
    },
    {
      id: "regeneratingTotals",
      title: "Regenerating Totals",
      description: `Currently processing order ${order} of ${lastOrder}`
    },
    {
      id: "savingTotals",
      title: "Saving Totals",
      description: "New totals are being saved to the database"
    },
    {
      id: "finalized",
      title: "Finalized",
      description: "Totals have been regenerated"
    }
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length
  });

  const regenerateTotals = async () => {
    const userConfirm = window.confirm(
      "Are you sure you want to regenerate the totals? This action is irreversible!"
    );

    if (!userConfirm) return window.alert("Cancelled.");

    const { status } = await api(
      `/api/administration/helpers/regenerate-totals/${socketID}`,
      "get"
    );
  };

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}/clients`);

    socket.on("connect", () => {
      setSocketID(socket.id);

      setSocketConnection(true);
    });

    socket.on("regenerateTotalsUpdate", (data) => {
      if (data.step === "dropDailyTotals" && data.stage === "starting") {
        setActiveStep(1);
      } else if (data.step === "fetchOrders" && data.stage === "starting") {
        setActiveStep(2);
      } else if (
        data.step === "regeneratingTotals" &&
        data.stage === "starting"
      ) {
        setActiveStep(3);
      } else if (data.step === "regenerateOrderTotal") {
        setOrder(data.order);
        setLastOrder(data.lastOrder);
        setPercentage(data.percent);
      } else if (data.step === "savingTotals" && data.stage === "starting") {
        setActiveStep(4);
      } else if (data.step === "savingTotals" && data.stage === "finished") {
        setActiveStep(5);
      }
    });

    return () => {
      socket.off();
    };
  });

  return (
    <Page
      title="Regenerate Totals"
      buttons={[
        {
          text: "Regenerate Totals",
          icon: IconReload,
          colorScheme: "blue",
          onClick: regenerateTotals
        }
      ]}
    >
      <Stepper index={activeStep} orientation="vertical" height="400px" gap={0}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box flexShrink={0}>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Page>
  );
};

export { RegenerateTotals };
