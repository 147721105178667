import { useEffect, useState } from "react";
import useChangeStartDate from "../../hooks/useChangeStartDate";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Switch,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChangeOrderStartDate } from "./ChangeOrderStartDate";
import { useAPI } from "../../hooks/useAPI";

export default ({
  isOpen,
  onClose,
  row,
  startDate,
  setStartDate,
  refresh,
  setRefresh,
}) => {
  // TODO: utilize a reducer here to break down the logic a bit
  // too much state garbage going on here
  const [shipSelection, setShipSelection] = useState("dontAdjust");
  const [applyToOrder, setApplyToOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: order,
    setData: setOrder,
    isLoading: isLoadingOrder,
  } = useAPI(`/api/orders/${row.orderID}`, "GET", "", "orderID" in row);

  const { shipDate, setShipDate, saveChanges, alert } = useChangeStartDate(
    row,
    shipSelection,
    startDate,
    applyToOrder,
    refresh,
    setRefresh,
    onClose,
    setIsLoading,
    order
  );

  const direction = useBreakpointValue({ base: true, md: false });

  const stackDirection = useBreakpointValue({
    base: "column",
    md: applyToOrder ? "row" : "column",
  });

  useEffect(() => {
    if (applyToOrder && shipSelection === "autoAdjust") {
      setShipSelection("dontAdjust");
    }
  }, [applyToOrder, shipSelection]);

  return (
    <Modal size={applyToOrder ? "5xl" : "xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={"1px"}>
          <Flex alignItems="center" justifyContent="space-between">
            Change Start Date
            <CloseButton onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Box mb={applyToOrder && 2}>
            {alert && (
              <Alert borderRadius={6} status={alert.variant} mb={1}>
                <AlertIcon />
                <AlertTitle>{alert.message}</AlertTitle>
              </Alert>
            )}
            <Grid
              templateColumns={
                direction
                  ? "repeat(1, 1fr)"
                  : applyToOrder
                  ? "repeat(3, 1fr)"
                  : "repeat(2, 1fr)"
              }
              mb={2}
              gap={2}
            >
              <GridItem>
                <FormControl>
                  <FormLabel>Order ID</FormLabel>
                  <Input readOnly value={row.orderID} />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Company Name</FormLabel>
                  <Input readOnly value={row.companyName} />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <Input readOnly value={row.type} />
                </FormControl>
              </GridItem>
              <GridItem
                colSpan={applyToOrder ? 3 : 1}
                display={!applyToOrder ? "block" : "none"}
              >
                <FormControl>
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <Flex direction={stackDirection}>
              <FormControl
                mb={stackDirection === "column" && 2}
                display="flex"
                alignItems="center"
              >
                <Switch
                  isChecked={applyToOrder}
                  onChange={(e) => setApplyToOrder(e.target.checked)}
                  mr={2}
                  id="adjustAllProducts"
                />
                <FormLabel
                  htmlFor="adjustAllProducts"
                  mb="0"
                  whiteSpace="nowrap"
                >
                  Adjust all products in this order
                </FormLabel>
              </FormControl>
              <Spacer />
              <RadioGroup
                w="100%"
                onChange={setShipSelection}
                value={shipSelection}
              >
                <Stack
                  direction="row"
                  justifyContent={applyToOrder && "right"}
                  spacing={2}
                >
                  <Radio value="dontAdjust">Don't Adjust</Radio>
                  <Tooltip
                    label={applyToOrder ? "Coming soon" : ""}
                    shouldWrapChildren
                  >
                    <Radio value="autoAdjust" isDisabled={applyToOrder}>
                      Automatically Adjust
                    </Radio>
                  </Tooltip>
                  <Radio value="manualAdjust">Manually Adjust</Radio>
                </Stack>
              </RadioGroup>
            </Flex>
          </Box>
          {applyToOrder && (
            <Box
              overflowX="auto"
              border="1px"
              borderColor="gray.200"
              borderRadius="lg"
            >
              <ChangeOrderStartDate
                order={order}
                setOrder={setOrder}
                isLoadingOrder={isLoadingOrder}
                row={row}
                shipSelection={shipSelection}
                setIsLoading={setIsLoading}
              />
            </Box>
          )}
          {!applyToOrder && (
            <Box>
              <FormControl>
                <Input
                  type={shipSelection == "autoAdjust" ? "text" : "date"}
                  hidden={shipSelection === "dontAdjust" ? true : false}
                  value={
                    ["autoAdjust", "manualAdjust"].includes(shipSelection)
                      ? shipDate
                      : ""
                  }
                  readOnly={shipSelection == "autoAdjust" ? true : false}
                  onChange={(e) =>
                    shipSelection == "manualAdjust"
                      ? setShipDate(e.target.value)
                      : ""
                  }
                />
              </FormControl>
            </Box>
          )}
        </ModalBody>
        <ModalFooter borderTopWidth={"1px"}>
          <Button
            colorScheme="blue"
            onClick={saveChanges}
            isLoading={isLoading}
          >
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
