import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  IconButton,
  Input,
  Spacer,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  MenuDivider,
  MenuGroup,
  Progress,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ResponsiveButton } from "./ResponsiveButton";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import React, { FC, ReactNode } from "react";

interface ButtonProps {
  text: string;
  icon?: ReactNode;
  colorScheme?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

interface InputProps {
  type: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DropdownItemProps {
  label: string;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface DropdownProps {
  label: string;
  icon: ReactNode;
  items: DropdownItemProps[];
}

interface ProgressProps {
  show: boolean;
  isLoading: boolean;
}

interface PageProps {
  title: string;
  backButton?: boolean;
  buttons?: ButtonProps[];
  inputs?: InputProps[];
  dropdowns?: DropdownProps[];
  sticky?: boolean;
  cardBodyPadding?: string;
  children?: ReactNode;
  progress?: ProgressProps;
}

export const Page: FC<PageProps> = ({
  title,
  backButton,
  buttons,
  inputs,
  dropdowns,
  sticky,
  cardBodyPadding,
  children,
  progress = { show: false, isLoading: false },
}) => {
  const navigate = useNavigate();

  const height = useBreakpointValue({ base: "80px", md: "40px" })

  return (
    <Card>
      <CardBody
        borderBottomWidth={"1px"}
        position={sticky ? "sticky" : "static"}
        top={sticky ? 0 : "auto"}
        backgroundColor="inherit"
        zIndex={100}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={{ base: "column", md: "row" }}
          zIndex={sticky ? 100 : "auto"}
          height={height}
        >
          <Flex alignItems={"center"}>
            {backButton && (
              <IconButton
                onClick={() => navigate(-1)}
                icon={<IconArrowLeft />}
                aria-label="Back"
              />
            )}
            <CardHeader>
              <Heading textAlign={{ base: "center", md: "left" }} size={"md"}>
                {title}
              </Heading>
            </CardHeader>
          </Flex>
          <Spacer />
          <Flex w={{ base: "100%", md: "auto" }} alignItems={"center"}>
            <HStack
              w={{ base: "100%", md: "auto" }}
              justifyContent={"flex-end"}
              flexWrap={{ base: "wrap", md: "nowrap" }}
            >
              {(inputs && inputs.length > 0) && inputs.map((input, index) => (
                <Input
                  key={index}
                  type={input.type}
                  value={input.value}
                  onChange={input.onChange}
                />
              ))}
              <ButtonGroup w={"100%"}>
                {(buttons && buttons.length > 0) && buttons.map((button, index) => (
                  <ResponsiveButton
                    text={button.text}
                    Icon={button.icon}
                    colorScheme={button.colorScheme}
                    onClick={button.onClick}
                    isLoading={button.isLoading}
                    disabled={button.disabled}
                    key={index}
                  />
                ))}
                {(dropdowns && dropdowns.length > 0) && dropdowns.map((dropdown, index) => (
                  <Menu key={index}>
                    <MenuButton
                      as={IconButton}
                      aria-label={dropdown.label}
                      {...(dropdown.icon && {icon: dropdown.icon})}
                      variant="outline"
                    />
                    <MenuList zIndex={1000}>
                      <MenuGroup title={dropdown.label}>
                        {dropdown.items.map((item, itemIndex) => (
                          <MenuItem key={itemIndex}>
                            <Checkbox
                              isChecked={item.isChecked}
                              onChange={item.onChange}
                              mr={2}
                            >
                              {item.label}
                            </Checkbox>
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                ))}
              </ButtonGroup>
            </HStack>
          </Flex>
        </Flex>
      </CardBody>
      {progress.show && progress.isLoading ? (
        <Progress size="xs" m={0} isIndeterminate />
      ) : null}
      <CardBody padding={cardBodyPadding}>{children}</CardBody>
    </Card>
  );
};
